import React from "react";
import exchangeRates from "../data/exchange-rates";
import { getLocale } from "../utils/locale";

const Button = ({ quantity, add, remove }) => {
  return quantity > 0 ? (
    <div className="flex flex-row">
      <button
        className="flex items-center justify-center flex-1 px-3 py-3 text-sm font-bold text-gray-800 uppercase bg-gray-200 border border-transparent rounded-md hover:bg-gray-300"
        onClick={remove}
      >
        -
      </button>
      <strong className="flex-[3] flex justify-center items-center text-gray-800">
        {quantity}
      </strong>
      <button
        className="flex items-center justify-center flex-1 px-3 py-3 text-sm font-bold text-white uppercase bg-red-600 border border-transparent rounded-md hover:bg-red-700"
        onClick={add}
      >
        +
      </button>
    </div>
  ) : (
    <button
      className="flex items-center justify-center w-full px-8 py-3 text-sm font-medium text-white uppercase bg-red-600 border border-transparent rounded-md hover:bg-red-700"
      onClick={add}
    >
      Add to box
    </button>
  );
};

export default ({
  dog,
  label,
  dogImage,
  meat,
  meatImage,
  quantity,
  addToBox,
  removeFromBox,
  quantityInBox,
  price,
  currency,
}) => {
  return (
    <div>
      <div
        className={`${
          quantityInBox > 0 ? "shadow-sm" : "shadow-md"
        } rounded-md overflow-hidden bg-white`}
      >
        <div className="relative">
          <div className="relative h-56 overflow-hidden">
            <svg
              className="absolute z-10 -bottom-10 h-20 w-[calc(100%+1px)] left-0 right-0 text-white"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="0,50 0,100 100,100 100,0" />
            </svg>
            {dogImage && (
              <img
                className="absolute bottom-0 z-20 object-cover w-16 h-16 bg-white border-4 border-white rounded-full right-2"
                src={dogImage}
                alt={dog}
              />
            )}
            <img
              className="absolute top-0 left-0 z-0 object-cover w-full h-full"
              src={meatImage}
              alt={meat}
            />
          </div>
        </div>
        <div className="flex flex-col py-3 pb-0">
          <div className="w-full sm:w-[74%] px-3">
            <div className="overflow-hidden text-xs font-light text-gray-300 uppercase whitespace-nowrap text-ellipsis">
              {label}
            </div>
            <h3 className="overflow-hidden text-xs font-light text-gray-400 uppercase whitespace-nowrap text-ellipsis">
              {dog}
            </h3>
            <h2 className="overflow-hidden font-medium text-gray-500 whitespace-nowrap text-ellipsis">
              {meat} <span className="text-sm text-gray-400">({quantity})</span>
            </h2>
          </div>
          <div className="flex items-end justify-end flex-1 pt-3 mt-4 text-lg text-right border-t sm:text-xl border-t-gray-50">
            <div className="-mt-8">
              <span className="inline-block px-3 bg-white">
                <span className="text-sm text-gray-400">{currency.symbol}</span>
                <strong className="font-medium text-gray-500">
                  {(price || 0).toLocaleString(getLocale(), {
                    minimumFractionDigits: 2,
                  })}
                </strong>
              </span>
            </div>
          </div>
        </div>
        <div className="p-2">
          <Button
            add={addToBox}
            remove={removeFromBox}
            quantity={quantityInBox}
          />
        </div>
      </div>
    </div>
  );
};
